
import { defineComponent, onMounted, reactive } from "vue";
import { RecordService } from "@/services/attendance/record/RecordService";
import Header from "@/components/Header.vue";
import Location from "@/components/Location.vue";
import { useRoute } from "vue-router";
import SystemUtils from "@/utils/SystemUtils";

export default defineComponent({
  name: "SignInDetail",
  components: {
    Header,
    Location,
  },
  setup() {
    const detail = reactive({
      userName: "",
      orgName: "",
      date: "",
      morningSignin: "",
      morningSignout: "",

      morningStatus: 0,
      morningStatusName: "",

      afternoonSignin: "",
      afternoonSignout: "",

      afternoonStatus: 0,
      afternoonStatusName: "",
    });

    const detailLog = reactive({ data: new Array() });

    const cityDetail = reactive({
      isEnablePmSign: 0,
    });

    const user = SystemUtils.loginUser;
    const service = new RecordService();

    const router = useRoute();
    const record_id = router.params["id"];

    const loadDetail = () => {
      const params = {
        id: record_id,
      };
      service
        .getDetail(params)
        .then(
          (result: any) => {
            if (result) {
              detail.userName = result.userName;
              detail.orgName = result.orgName;
              detail.date = result.date;

              detail.morningSignin = result.morningSignin;
              detail.morningSignout = result.morningSignout;
              detail.morningStatus = result.morningStatus;
              detail.morningStatusName = result.morningStatusName;

              detail.afternoonSignin = result.afternoonSignin;
              detail.afternoonSignout = result.afternoonSignout;
              detail.afternoonStatus = result.afternoonStatus;
              detail.afternoonStatusName = result.afternoonStatusName;
            }
          },
          (e: any) => {}
        )
        .catch((c: any) => {});
    };

    const locationValue = reactive({
      lon: new Array(),
      lat: new Array(),
    });
    const getDetaillog = () => {
      const params = {
        id: record_id,
      };
      service
        .getDetaillog(params)
        .then(
          (result: any) => {
            if (result) {
              detailLog.data = result.logs;
              result.logs.forEach((item: any) => {
                locationValue.lon.push(item.longitude);
                locationValue.lat.push(item.latitude);
              });
            }
          },
          (e: any) => {}
        )
        .catch((c: any) => {});
    };

    const getUserAttenCity = () => {
      const params = {
        userId: user.id,
      };
      service
        .getUserAttenCity(params)
        .then(
          (result: any) => {
            if (result) {
              cityDetail.isEnablePmSign = result.isEnablePmSign;
              console.log(cityDetail.isEnablePmSign);
            }
          },
          (e: any) => {}
        )
        .catch((c: any) => {});
    };

    const initDate = () => {
      getUserAttenCity();
      loadDetail();
      getDetaillog();
    };

    onMounted(() => {
      initDate();
    });

    return { detail, detailLog, cityDetail, locationValue };
  },
});
